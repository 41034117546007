import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
//import PostListing from "../components/PostListing/PostListing";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import SideNav from "../components/SideNav";
import ProductCard from "../components/Product/ProductCard";
import Button from "@material-ui/core/Button";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    margin: "25px 0",
  },
  /*  pagination: {
    margin: 4,
    padding: 7,
    backgroundColor: "gray",
    color: "black",
  },*/
}));
function Listing({ pageContext, data }) {
  const classes = useStyles();

  function renderPaging() {
    const { currentPageNum, pageCount } = pageContext;
    const prevPage = currentPageNum - 1 === 1 ? "/" : `/${currentPageNum - 1}/`;
    const nextPage = `/${currentPageNum + 1}/`;
    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;

    return (
      <div className={classes.root}>
        {!isFirstPage && (
          <Link /*className={classes.pagination}*/ to={prevPage}>
            <Button> السابق </Button>
          </Link>
        )}
        {[...Array(pageCount)].map((_val, index) => {
          const pageNum = index + 1;
          return (
            <Link
              key={`listing-page-${pageNum}`}
              to={pageNum === 1 ? "/" : `/${pageNum}/`}
              /*className={classes.pagination}*/
            >
              <Button>{pageNum}</Button>
            </Link>
          );
        })}
        {!isLastPage && (
          <Button>
            <Link /*className={classes.pagination}*/ to={nextPage}> التالى </Link>
          </Button>
        )}
      </div>
    );
  }

  const productEdges = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <SEO />

      <Helmet title={config.siteTitle} />

      <Container style={{ padding: "50px 15px" }}>
        <Grid container spacing={3}>
          <Grid item md={3} xs={12} sm={12} spacing={1}>
            <SideNav />
          </Grid>

          <Grid item md={9} xs={12} sm={12} spacing={1}>
            <Grid container spacing={1}>
              {productEdges.map((product, index) => (
                <Grid item md={4} xs={12} sm={6} key={index}>
                  <Link to={`/product${product.node.fields.slug}`}>
                    <ProductCard
                      image={
                        product.node.frontmatter.cover.childImageSharp.fluid
                      }
                      title={product.node.frontmatter.title}
                      category={product.node.frontmatter.category}
                    />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {renderPaging()}
    </Layout>
  );
}

export default Listing;

/* eslint no-undef: "off" */
export const listingQuery = graphql`
  query ListingQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
            category
          }
        }
      }
    }
  }
`;
